const BLOCKCHAIN = {
  USDT_ERC20:
    process.env.REACT_APP_MAINNET === "1"
      ? "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
      : "0xe09ceE0bDcf631Fcf5baa4f00E6e00A28462748a",
  USDC_ERC20:
    process.env.REACT_APP_MAINNET === "1"
      ? "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359"
      : "0x1F4Db9707e7851818b19ae659037553d1826C4Fd",
  ESCROW_ROLE: "0x3b2a61e18f61be1272a467c8cbf17c05fd965ec69fdf24c3f07f078a6071aeea",
  FACTORY_ROLE: "0xd2e4c2619ea6e0faebc405d89445161c041e30fe03373ea0473da142d57d4514",
  SIGNATURE_WALLET_ADDRESS: "0xBB2236A1E8f066d9286Ef72c076235f9E292d0Fe",
  MARKETPLACE_ROLE: "0x0ea61da3a8a09ad801432653699f8c1860b1ae9d2ea4a141fadfd63227717bc8"
};

export default BLOCKCHAIN;
