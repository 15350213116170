import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
// routes
import SuperAdminRoutes from "./SuperAdminRoutes";
import SubAdminRoutes from "./SubAdminRoutes";
import BrandAdminRoutes from "./BrandAdminRoutes";
import UserRoutes from "./UserRoutes";
import LoginRoutes from "./LoginRoutes";
import TrackingRoutes from "./TrackingRoute";
import ProfileRoutes from "./ProfileRoutes";

export default function ThemeRoutes() {
  const userData = useSelector((state) => state.auth);
  let routes;
  if (userData.user && userData.user.role === "Super Admin") {
    routes = [UserRoutes, SuperAdminRoutes, TrackingRoutes, ProfileRoutes];
  } else if (userData.user && userData.user.role === "Sub Admin") {
    routes = [UserRoutes, SubAdminRoutes, TrackingRoutes, ProfileRoutes];
  } else if (userData.user && userData.user.role === "Brand Admin") {
    routes = [UserRoutes, BrandAdminRoutes, TrackingRoutes, ProfileRoutes];
  } else if (userData.user && userData.user.role === "User" && userData.user.isVerified === true) {
    routes = [UserRoutes, TrackingRoutes, ProfileRoutes];
  } else if (userData.user && userData.user.role === "User" && userData.user.isVerified === false) {
    routes = [UserRoutes, TrackingRoutes, LoginRoutes];
  } else {
    routes = [UserRoutes, LoginRoutes, TrackingRoutes];
  }
  return useRoutes(routes);
}
