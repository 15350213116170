export const GET_ALL_SUBADMIN_LIST = "get_all_subadmin_list";
export const GET_ALL_SUBADMIN_LIST_SUCCESS = "get_all_subadmin_list_success";
export const DELETE_STATUS_SUBADMIN = "delete_status_subadmin";
export const ADD_SUBADMIN = "add_subadmin";
export const UPDATE_SUBADMIN = "update_subadmin";
export const DELETE_SUBADMIN = "delete_subadmin";
export const CHANGE_SUBADMIN_STATUS = "change_subadmin_status";
export const CHANGE_ROLE = "change_role";
export const CHANGE_SUBADMIN_MINTING_ACCESS = "change_subadmin_minting_access";
export const ASSIGN_BRANDCATEGORY = "assign_brandcategory";
export const GET_ALL_GENERAL_SETTING_STATUS_SUCCESS = "get_all_general_setting_status_success";
export const GET_ALL_GENERAL_SETTING_STATUS = "get_all_general_setting_status";
export const ADD_GENERAL_SETTING_STATUS = "add_general_setting_status";
export const OPTIONAL_COUNTRIES = "optional_countries";
export const GET_GENERAL_SETTING_INFO_SUCCESS = "get_general_setting_info_success";
export const ADD_GENERAL_CONTACT_INFO = "add_general_contact_info";
export const FETCH_EARNINGS = "fetch_earnings";
export const FETCH_EARNINGS_SUCCESS = "fetch_earnings_success";

export const FETCH_WITHDRAW_HISTORY = "fetch_withdraw_history";
export const FETCH_WITHDRAW_HISTORY_SUCCESS = "fetch_withdraw_history_success";
export const RELEASE_SCHEDULE = "release_scheduled";
export const RELEASE_SCHEDULE_SUCCESS = "release_scheduled_success";
export const GET_ALL_MY_ACTIVITY_SUBADMIN = "get_all_my_activity_subadmin";
export const GET_ALL_MY_ACTIVITY_SUBADMIN_SUCCESS = "get_all_my_activity_subadmin_success";
export const GET_MY_ACTIVITY_SUBADMIN_DETAIL = "get_my_activity_subadmin_detail";
export const GET_MY_ACTIVITY_SUBADMIN_DETAIL_SUCCESS = "get_all_my_activity_subadmin_detail_success";
export const UPDATE_RETURN_MY_ACTIVITY_SUBADMIN = "update_return_my_activity_subadmin";
export const GET_COUNTRY_LIST = "get_country_list";
export const GET_COUNTRY_LIST_SUCCESS = "get_country_list_success";
